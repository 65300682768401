const anime = require('animejs');

function textAnimate() {
  // import anime from 'animejs/lib/anime.es.js';

  var ml4 = {};
  ml4.opacityIn = [0,1];
  ml4.scaleIn = [0.2, 1];
  ml4.scaleOut = 3;
  ml4.durationIn = 400;
  ml4.durationOut = 300;
  ml4.delay = 250;

  anime.timeline({loop: 1})
  .add({
      targets: '.ml4 .letters-1',
      opacity: ml4.opacityIn,
      scale: ml4.scaleIn,
      duration: ml4.durationIn
    }).add({
        targets: '.ml4 .letters-1',
        opacity: 0,
        scale: ml4.scaleOut,
        duration: ml4.durationOut,
        easing: "easeInExpo",
        delay: ml4.delay
    }).add({
        targets: '.ml4 .letters-2',
        opacity: ml4.opacityIn,
        scale: ml4.scaleIn,
        duration: ml4.durationIn
    }).add({
        targets: '.ml4 .letters-2',
        opacity: 0,
        scale: ml4.scaleOut,
        duration: ml4.durationOut,
        easing: "easeInExpo",
        delay: ml4.delay
    }).add({
        targets: '.ml4 .letters-3',
        opacity: ml4.opacityIn,
        scale: ml4.scaleIn,
        duration: ml4.durationIn
    }).add({
        targets: '.ml4 .letters-3',
        opacity: 0,
        scale: ml4.scaleOut,
        duration: ml4.durationOut,
        easing: "easeInExpo",
        delay: ml4.delay
    }).add({
        targets: '.ml4 .letters-4',
        opacity: ml4.opacityIn,
        scale: ml4.scaleIn,
        duration: ml4.durationIn
    }).add({
        targets: '.ml4 .letters-4',
        opacity: 0,
        scale: ml4.scaleOut,
        duration: ml4.durationOut,
        easing: "easeInExpo",
        delay: ml4.delay
    }).add({
        targets: '.ml4',
        opacity: 0,
        duration: 500,
        delay: 500
    });
}

textAnimate();

// document.documentElement.onload = function(){
//     document.getElementById("loader").style.display = "block";
// };

//or

// document.documentElement.addEventListener("load", function(){
//     document.getElementById("loader").style.display = "block";
// });

window.onload = function(){
    document.body.style.overflow = "inherit";
    document.getElementById("loader").classList.add("hide-loader");

    // .5 seconds later, hide the splash
    setTimeout(function(){

        document.getElementById("loader").style.display = "none";

      // >> Set cookie to visited here <<
    }, 1000);
};

//or

// window.addEventListener("load", function(){
//     document.getElementById("loader").style.display = "none";
// });


var allAnchors = document.getElementsByTagName("a");

for ( var counter = 0; counter < allAnchors.length; counter++)
{
	allAnchors[counter].addEventListener( "click", function(e){
        if (this.target == "_blank")
        {
        } else {
            e.preventDefault();
            var src = this.getAttribute( "href" );
            if (src == "#") {
                location.href = src;
            } else {
                if (src == "") {
                    location.href = src;
                } else {
                    document.body.style.overflow = "hidden";
                    // textAnimate();
                    document.getElementById("loader").style.display = "flex";
                    document.getElementById("loader").style.opacity = "1";
                    location.href = src;
                }
            }
        }
	}, false );
}