// import $ from 'jquery';
// COMPONENTS
// require('./general.js');
// require('./components/dropdown.js');
// require('./components/olw-carousel2.js');
// require('./components/overlay-menu.js');
// require('./components/mapplace.js');
// require('./components/smooth-scroll.js');
// require('./components/sticky-kit.js');
// require('./components/edittable.js');
// require('./components/collapse.js');
// require('./components/modal.js');

// require('./login.js');

//require('perfect-scrollbar');
// require('bootstrap');

// import bootstrap from '../../node_modules/bootstrap/dist/js/bootstrap.bundle.js'

document.body.style.overflow = "hidden";

require('./components/loader.js');

require('./components/opt.js');

// var myModal = new bootstrap.Modal(document.getElementById("exampleModal"), {});
// document.onreadystatechange = function () {
//   myModal.show();
// };